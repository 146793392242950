import Amplify from 'aws-amplify';

// *************************** Import CSS *************************** //

import "./nav-header-footer.css";

// ************************** Import Images ************************ //

import "../assets/img/logos/ydb-website-logo.png";
import "../assets/img/social/facebook-icon.svg";
import "../assets/img/social/instagram-icon.svg";
import "../assets/img/icons/phone.svg";
import "../assets/img/icons/envelope.svg";

// *************************************************** Insert Navigation Menu **************************************************** //

class FirstNav extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container">

      <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-maroon" aria-label="Navigation">

        <div class="container">

          <!-- YDB Logo -->

          <a class="navbar-brand" href="./index.html">
            <img src="ydb-website-logo.png" class="ydb-navbar-logo d-inline-block align-top mt-2" alt="Your Dog's Best Logo" />
            <span class="hidden">Your Dog's Best</span>
          </a>

          <!-- Toggle Button for Mobile Nav -->

          <button class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
            type="button" data-bs-toggle="collapse" data-bs-target="#navbar-items"
            aria-controls="navbar-items" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-icon top-bar"></span>
            <span class="toggler-icon middle-bar"></span>
            <span class="toggler-icon bottom-bar"></span>
          </button>

          <!-- Navigation Menu -->

          <div id="navbar-items" class="collapse navbar-collapse">

            <ul class="navbar-nav ms-auto text-center mb-2 mb-sm-0">

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="index.html">Home</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="training-packages.html">Training Packages</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="about.html">Meet The Trainer</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="faqs.html">FAQs</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="contact.html">Contact Us</a>
              </li>

              <li class="nav-item mx-auto ms-lg-1 me-lg-2 pt-2 pt-lg-0">
                <a href="https://www.facebook.com/yourdogsbest" target="_blank">
                  <img src="facebook-icon.svg" width="30" height="30" class="Facebook icon" alt="Facebook icon">
                </a>
              </li>

              <li class="nav-item mx-auto ms-lg-1 me-lg-2 pt-4 pt-lg-0">
                <a href="https://www.instagram.com/yourdogsbest" target="_blank">
                  <img src="instagram-icon.svg" width="30" height="30" class="img" alt="Instagram icon">
                </a>
              </li>

            </ul>

          </div>

        </div>

      </nav>

    </div> <!-- container -->

    `
  }
}

// ************************************************ Insert Considering Training *********************************************************** //

class Considering extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container py-3">

      <div class="row col-12 mx-auto">

        <!-- Considering Training Text Section -->

        <div class="col-md-7 col-lg-7 px-4">

          <p class="text-center text-md-start considering-text text-medium-blue">CONSIDERING TRAINING?</p>

          <p class="considering-text-sub text-md-start text-sm-center text-size-secondary-heading text-medium-blue">We&#39;d love to talk more about what you need.</p>

          <p class="considering-text-1 col-lg-10 text-dark-blue text-md-start text-sm-center">Your Dog's Best trains clients on-site at our Mobile, AL area training facility,
          locally in the owner's home, and travels to other locations to train by special request.</p>

          <p class="considering-text-2 col-lg-10 text-dark-blue text-md-start text-sm-center">Contact us today to learn more about our services or request a</p>

          <p class="free-eval col-lg-10 text-dark-blue text-md-start text-sm-center">
            <span class="text-medium-blue bold">FREE TRAINING EVALUATION.</span>
          </p>

          <div class="see-packages-button text-md-start text-sm-center">
            <a href="training-packages.html"><button type="button" class="btn text-md-start">SEE OUR TRAINING PACKAGES</button></a>
          </div>

        </div>

        <!-- Phone & Email Section -->

          <div id="contact-box" class="col-md-5 my-auto mx-auto">

          <div class="hr1 mt-4">
            <hr class="horizontal mx-auto" width="80%">
          </div>

            <!-- BEGIN: Phone -->

              <div class="py-3 py-lg-2 text-center">

                <div class="icon icon-shape bg-gradient-maroon shadow text-center rounded-circle mx-auto">
                  <img class="h3 text-info pt-2" height="35" src="phone.svg" alt="Phone icon">
                </div>

                <p class="text-h3 text-dark-blue mt-3 mb-1">Phone</p>

                <a href="tel:720-595-6693" class="text-dark-blue">(720) 595-6693</a>

              </div>

          <!-- END: Phone -->

          <div>
            <hr class="horizontal mx-auto" width="80%">
          </div>

          <!-- BEGIN: Email -->

            <div class="py-3 py-lg-2 text-center">

              <div class="icon icon-shape bg-gradient-maroon shadow text-center rounded-circle mx-auto">
                <img class="h3 text-info pt-2" height="35" src="envelope.svg" alt="Email icon">
              </div>

              <p class="text-h3 text-dark-blue mt-3 mb-1">Email</p>

              <a class="text-dark-blue" href=mailto:frank@yourdogsbest.com?subject=Inquiry%20From%20Your%20Dog's%20Best%20Website>frank@yourdogsbest.com</a>

            </div>

          <!-- END: Email -->

        </div> <!-- Right-Column -->

     </div> <!-- row -->

    </div> <!-- container -->

    `
  }
}

// ************************************************* Insert Footer ***************************************** //

class Footer extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container footer-container px-1">

      <a href="./index.html">
        <img class="footer-logo-img ms-4" src="ydb-website-logo.png" alt="Your Dog's Best Logo">
      </a>

      <div>
        <a href="./policies.html" class="text-white">Policies &#38; Terms of Service</a>
      </div>

      <!-- BEGIN: Social Media Icons -->

      <div id="social-media-footer" class="social-media-footer">

      <ul role="list" class="footer-social pt-2" aria-label="Social media links">

        <li>
          <a class="social-link" aria-label="Facebook link" href="https://www.facebook.com/yourdogsbest" target="_blank">
            <img src="facebook-icon.svg" width="30" height="30" class="Facebook icon" alt="Facebook icon">
          </a>
        </li>

        <li>
        <a class="social-link" aria-label="Instagram link" href="https://www.instagram.com/yourdogsbest" target="_blank">
          <img src="instagram-icon.svg" width="30" height="30" class="img" alt="Instagram icon">
        </a>
        </li>

      </ul>

      </div>

      <!-- END: Social Media Icons -->

      <p class="copyright text-size-h5 text-white">© <span class="currentYear">2023</span> Your Dog's Best, LLC</p>

      </div>

    `
  }
}

// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('first-nav', FirstNav);
customElements.define('considering-training', Considering);
customElements.define('footer-nav', Footer);

// *********************** Automatically Update Copyright Year *********************** //

const yearSpan = document.querySelector(".currentYear");
const currentYear = new Date();
yearSpan.innerText = currentYear.getFullYear();

// *********************** Highlight Active Links on Top Navigation *********************** //

document.querySelectorAll(".nav-link").forEach((link) => {
    if (link.href === window.location.href) {
        link.classList.add("active");
        link.setAttribute("aria-current", "page");
    }
});
