import Amplify from '@aws-amplify/api';

import "./index.css";

// ****************************** Import Images ******************************** //

  // ** LOADING SPINNER - Business Card Image ** //
  import "./assets/img/logos/marley-bizcard-small.png";

  // ** HEADER SECTION - Business Card Image ** //
  import "./assets/img/logos/ydb-bizcard-image.png";

  // ** TRAINING COMMANDS SECTION - Dog Training Icons ** //
  import "./assets/img/dog-training-icons/heel-on-leash.svg";
  import "./assets/img/dog-training-icons/heel-off-leash.svg";
  import "./assets/img/dog-training-icons/sit-stay.svg";
  import "./assets/img/dog-training-icons/down-stay.svg";
  import "./assets/img/dog-training-icons/come.svg";
  import "./assets/img/dog-training-icons/break.svg";
  import "./assets/img/dog-training-icons/no-bark.svg";
  import "./assets/img/dog-training-icons/leave-it.svg";

  // ** TRAINING COMMANDS SECTION - Description Image ** //
  import "./assets/img/frank-marley/frank-walk-marley.jpg";

  // ** MEET A FEW OF OUR CLIENTS SECTION - Client Images ** //
  import "./assets/img/dog-pics/bear-honey-marley-ziggy.jpg";
  import "./assets/img/dog-pics/billion-and-pup.jpg";
  import "./assets/img/dog-pics/honey-running.jpg";
  import "./assets/img/dog-pics/dogs-hiking-co.jpg";

  // ** SEE WHAT THEIR OWNERS ARE SAYING SECTION - Client Images ** //
  import "./assets/img/dog-headshots/honey-upside-down.jpeg";
  import "./assets/img/dog-headshots/daisy-headshot.jpg";
  import "./assets/img/dog-headshots/nelly-headshot.jpg";
  import "./assets/img/icons/quotation-marks.svg";

  // ** GET IN TOUCH SECTION SECTION - Contact Icons ** //
  import "./assets/img/icons/phone.svg";
  import "./assets/img/icons/envelope.svg";

// **************************** Add Top Nav & Footer ************************** //

import './nav-header-footer/nav-header-footer.js';
import './nav-header-footer/nav-header-footer.css';

// **************************** Loading Spinner ****************************** //

$(window).on('load', function(){
  document.getElementById("loading-spinner").style.display = "block";
  setTimeout(removeSpinner, 1000);
});

function removeSpinner(){
  $( "#loading-spinner" ).fadeOut(100, function() {
    $( "#loading-spinner" ).remove();
  });
}
